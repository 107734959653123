import React from 'react'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from 'styled-components'

import config from '../utils/siteConfig'
import { gradient, breakpoints, space, mediaQueries, fontSizes, mainOffset, colors, lineHeights } from '../utils/tokens'

import SEO from '../components/SEO'
import RichArticle from '../components/RichArticle'
import { MainLayout } from '../components/MainLayout'

const TheLab = ({ data }) => {
  const { hero, slug, title } = data.contentfulPage
  const postNode = data.contentfulPage

  return (
    <MainLayout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />
      <main
        css={css({
          paddingTop: `${space[5]}px`,
          [mediaQueries.md]: {
            paddingTop: `${space[6]}px`,
            paddingBottom: `${space[6]}px`,
          },
        })}
      >
        <div
          css={css({
            padding: `0 ${space[4]}px`,
            [mediaQueries.md]: {
              maxWidth: `${breakpoints.xxl}`,
              padding: `0 ${space[4]}px`,
              margin: `auto`,
            },
          })}
        >
          <section
            css={css({
              [mediaQueries.md]: {
                padding: `${space[4]}px ${space[4]}px`,
              },
            })}
          >
            <div
              css={css({
                marginBottom: `${space[5]}px`,
                [mediaQueries.lg]: {
                  maxWidth: breakpoints.md,
                  marginBottom: `${space[6]}px`,
                  marginLeft: `${space[6]}px`,
                },
              })}
            >
              <h1
                css={css({
                  fontSize: fontSizes[5],
                  fontWeight: `bold`,
                  marginBottom: `${space[4]}px`,
                  textAlign: `left`,
                  [mediaQueries.md]: {
                    fontSize: fontSizes[6],
                  },
                })}
              >
                {hero.title}
              </h1>
              {hero.subtitle && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: hero.subtitle.childMarkdownRemark.html,
                  }}
                  css={css({
                    fontSize: fontSizes[3],
                    textAlign: `left`,
                    lineHeight: lineHeights.body,
                  })}
                />
              )}
            </div>
          </section>
          {data.contentfulPage.pageSection.map((section, index) => {
            return (
              <section
                key={index}
                css={css({
                  marginBottom: `${space[4]}px`,
                  [mediaQueries.lg]: {
                    display: `grid`,
                    gridTemplateColumns: `1fr 1fr`,
                    gridGap: `${space[5]}px`,
                  },
                })}
              >
                {section.image && (
                  <div
                    css={css({
                      backgroundColor: colors.tertiary,
                      [mediaQueries.lg]: {
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                      },
                    })}
                  >
                    <Img
                      fixed={section.image.fixed}
                      alt={section.image.title}
                      css={css`
                        margin-bottom: ${space[4]}px;
                        display: none !important;
                        ${mediaQueries.lg} {
                          display: inline-block !important;
                          margin-bottom: 0;
                        }
                      `}
                    />
                    <Img
                      fluid={section.image.fluid}
                      alt={section.image.title}
                      css={css`
                        display: block !important;
                        ${mediaQueries.lg} {
                          display: none !important;
                          margin-bottom: 0;
                        }
                        &::after {
                          content: '';
                          position: absolute;
                          z-index: 1;
                          width: 100%;
                          height: 100%;
                          top: 0;
                          left: 0;
                          background-image: ${gradient.primary};
                        }
                      `}
                    />
                  </div>
                )}
                <div>
                  <div
                    css={css({
                      textAlign: `left`,
                      marginBottom: `${space[4]}px`,
                      marginTop: `${space[4]}px`,
                    })}
                  >
                    <h2
                      css={css({
                        fontSize: fontSizes[4],
                        fontWeight: `bold`,
                        marginBottom: `${space[2]}px`,
                      })}
                    >
                      {section.title}
                    </h2>
                    <span
                      css={css({
                        color: colors.primaryBrand,
                        fontWeight: `700`,
                      })}
                    >
                      {section.subtitle}
                    </span>
                  </div>
                  {section.richTextBody && (
                    <RichArticle body={section.richTextBody} />
                  )}
                </div>
              </section>
            )
          })}
        </div>
      </main>
    </MainLayout>
  )
}

export default TheLab

export const labPageQuery = graphql`
         query {
           contentfulPage(slug: { eq: "the-lab" }) {
             hero {
               ...HeroFragment_withNoBackground
             }
             title
             slug
             metaDescription {
               internal {
                 content
               }
             }
             pageSection {
               title
               subtitle
               richTextBody {
                 raw
                 references {
                   ...RichTextArticleFragment
                 }
               }
               body {
                 internal {
                   content
                 }
               }
               image {
                 title
                 fluid(maxHeight: 600) {
                   ...GatsbyContentfulFluid_withWebp
                 }
                 fixed(height: 400, quality: 100) {
                   ...GatsbyContentfulFixed_withWebp
                 }
               }
               cards {
                 ... on ContentfulCard {
                   title
                   description {
                     internal {
                       content
                     }
                   }
                 }
               }
             }
           }
           allContentfulBlog(
             sort: { fields: [originalPublishDate, createdAt], order: DESC }
           ) {
             edges {
               node {
                 ...BlogCardFragment
                 originalPublishDate(formatString: "MMMM DD, YYYY")
                 createdAt(formatString: "MMMM DD, YYYY")
                 fields {
                   path
                 }
               }
             }
           }
         }
       `
